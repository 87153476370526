<template>
    <div class="available-labels">
        <h1 class="my-1 text-sm">
            {{ $t('KEYWORD.AVAILABLE_CUSTOM_LABELS', 'Available Custom Labels') }} ({{ availableLabels.length }})
        </h1>
        <p class="flex space-x-2">
            <search
                :selected-labels="selectedLabels"
                :available-labels="availableLabels"
                :filter="filter"
                :disabled="availableLabels.length === 0"
                @add-label="$emit('add-labels', [$event])"
                @update-filter="$emit('update-filter', $event)"
            />
            <GroupBySelector v-model="groupBy" />
        </p>
        <Scrollbar
            ref="scrollbar"
            class="max-h-64"
            data-test-id="label-groups"
        >
            <LabelGroup
                v-for="group in groupedLabels"
                :key="group.name"
                :name="group.name"
                :custom-labels="group.customLabels"
                :no-grouping="groupBy === GroupByEnum.NO_GROUPING"
                @add-labels="$emit('add-labels', $event)"
            />
        </Scrollbar>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import Search from './Search.vue';
import GroupBySelector from './GroupBySelector.vue';
import LabelGroup from './LabelGroup.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import { CustomLabel, GroupByEnum } from '../../store/types';
import i18next from 'i18next';
import useUserStore from '@/user/store';
import useCustomLabelStore from '@/custom-labels/store';

export default defineComponent({
    components: {
        Search,
        GroupBySelector,
        Scrollbar,
        LabelGroup,
    },
    props: {
        availableLabels: { type: Array as () => Array<CustomLabel>, required: true },
        selectedLabels: { type: Array, required: true },
        filter: { type: Object as () => { value: string | null, type: 'tag' | 'category' | 'creator' | null }, required: true },
    },
    emits: ['add-labels', 'update-filter'],
    setup(props) {
        const { state: userState } = useUserStore();
        const { state: customLabelState } = useCustomLabelStore();
        const scrollbar = ref();
        const groupBy = ref<GroupByEnum>(GroupByEnum.NO_GROUPING);

        const groupedLabels = computed<Array<{ name: string, customLabels: Array<CustomLabel> }>>(() => {
            switch (groupBy.value) {
            case GroupByEnum.BY_CATEGORY: {
                return Object.entries(props.availableLabels.reduce((labels, label) => {
                    const category = label.category || (label.category_id && customLabelState.categories.find(cat => cat.id === label.category_id)?.name) || 'no category';

                    if (!labels[category]) labels[category] = [];

                    labels[category].push(label);

                    return labels;
                }, {} as { [category: string]: Array<CustomLabel> }))
                    .map(([category, customLabels]) => {
                        return {
                            name: category,
                            customLabels
                        };
                    })
                    .sort((g1, g2) => {
                        if (g1.name === 'no category') return 1;
                        if (g2.name === 'no category') return -1;

                        return g1.name.toLowerCase() <= g2.name.toLowerCase() ? -1 : 1;
                    })
                    .map(group => ({
                        ...group,
                        name: group.name === 'no category' ? i18next.t('CUSTOM_LABELS.NO_CATEGORY', 'No category') : group.name
                    }));
            }
            case GroupByEnum.BY_TAG: {
                return Object.entries(props.availableLabels.reduce((labels, label) => {
                    const tags = label.tags.length === 0 ? ['no tag'] : label.tags;

                    tags.forEach(tag => {
                        if (!labels[tag]) labels[tag] = [];

                        labels[tag].push(label);
                    });

                    return labels;
                }, {} as { [tag: string]: Array<CustomLabel>} ))
                    .map(([tag, customLabels]) => {
                        return {
                            name: tag,
                            customLabels,
                        };
                    })
                    .sort((g1, g2) => {
                        if (g1.name === 'no tag') return 1;
                        if (g2.name === 'no tag') return -1;

                        return g1.name.toLowerCase() <= g2.name.toLowerCase() ? -1 : 1;
                    })
                    .map(group => {
                        return {
                            ...group,
                            name: group.name === 'no tag' ? i18next.t('CUSTOM_LABELS.NO_TAG', 'No tag') : group.name,
                        };
                    });
            }
            case GroupByEnum.BY_CREATOR: {
                return Object.entries(props.availableLabels.reduce((labels, label) => {
                    const creator = typeof label.creator === 'string' ? label.creator : label.creator.full_name ;

                    if (!labels[creator]) labels[creator] = [];

                    labels[creator].push(label);

                    return labels;
                }, {} as { [creator: string]: Array<CustomLabel> }))
                    .map(([creator, customLabels]) => {
                        return {
                            name: creator,
                            customLabels
                        };
                    })
                    .sort((g1, g2) => {
                        if (g1.name === 'User Deleted') return 1;
                        if (g2.name === 'User Deleted') return -1;

                        if (g1.name === userState.name) return -1;
                        if (g2.name === userState.name) return 1;

                        return g1.name.toLowerCase() <= g2.name.toLowerCase() ? -1 : 1;
                    })
                    .map(group => ({
                        ...group,
                        name: group.name === 'User Deleted' ? i18next.t('GLOBAL.DELETED_USER', 'Deleted user') : group.name
                    }));
            }
            case GroupByEnum.NO_GROUPING:
            default:
                return [
                    {
                        name: 'no grouping',
                        customLabels: props.availableLabels
                    }
                ];
            }
        });

        watch(groupBy, () => scrollbar.value?.scrollToTop());

        return {
            scrollbar,
            groupBy,
            groupedLabels,
            GroupByEnum,
        };
    },

});
</script>
