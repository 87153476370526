<template>
    <div
        v-on-clickaway="clickawayHandler"
        class="search flex items-center pb-2"
        style="width: 430px"
    >
        <div
            class="relative flex-grow"
        >
            <z-input
                ref="inputRef"
                v-model.trim="query"
                :placeholder="$t('GLOBAL.KEYWORD_SEARCH')"
                :disabled="disabled"
                @focus="handleFocus"
            >
                <template #startIcon>
                    <z-icon icon="search" />
                </template>
            </z-input>
            <div
                v-if="filter.value"
                class="z-50 relative bg-bg w-4/5 text-sm"
                style="margin-top: -26px; margin-left: 27px"
                @click="editFilter"
            >
                {{ filter.value }}
                <span class="text-neutral-800">
                    - {{ $t('HOME.KEYWORD', 'Custom label') }} {{ filter.type }}
                </span>
            </div>
            <results
                v-if="query && focused"
                :query="query"
                :selected-labels="selectedLabels"
                :available-labels="availableLabels"
                @add-label="handleAddLabel"
                @update-filter="handleUpdateFilter"
            />
        </div>
        <span
            v-if="query || (filter.value && filter.type)"
            class="pl-4 cursor-pointer text-neutral-700"
            @click="clearSearch"
        >
            {{ filter.value && filter.type ? $t('CUSTOM_LABELS.CLEAR_FILTER', 'Clear filter') : $t('CUSTOM_LABELS.CLEAR_SEARCH', 'Clear search') }}
        </span>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref, nextTick} from 'vue';
import { directive as onClickaway } from 'vue-clickaway2';

import ZIcon from '@/components/ui/Icon.vue';
import Results from './Results.vue';

import { CustomLabel } from '../../store/types';

export default defineComponent({
    components: {
        ZIcon,
        Results,
    },
    directives: {
        onClickaway
    },
    props: {
        selectedLabels: { type: Array, required: true },
        availableLabels: { type: Array as () => Array<CustomLabel>, required: false, default: null },
        filter: { type: Object as () => { value: string | null, type: 'tag' | 'category' | 'creator' | null }, required: true },
        disabled: { type: Boolean, default: false },
    },
    setup(_, context) {
        const inputRef = ref();
        const query = ref('');
        const focused = ref(false);

        function handleAddLabel(id: number) {
            context.emit('add-label', id);
            clearSearch();
        }

        function clearSearch() {
            resetFilter();
            query.value = '';
        }

        function resetFilter() {
            context.emit('update-filter', { value: null, type: null });
        }

        async function editFilter() {
            resetFilter();
            await nextTick();
            inputRef.value.focus();
        }

        function handleUpdateFilter(payload) {
            query.value = payload.value;
            focused.value = false;
            context.emit('update-filter', payload);
        }

        function handleFocus() {
            focused.value = true;
        }

        function clickawayHandler() {
            focused.value = false;
        }

        return {
            query,
            handleAddLabel,
            clearSearch,
            handleUpdateFilter,
            handleFocus,
            focused,
            clickawayHandler,
            inputRef,
            editFilter,
        };
    },
});
</script>
