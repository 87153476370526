<template>
    <div
        ref="container"
        class="z-scrollbar-container"
        @wheel.prevent="handleWheel"
        @scroll.prevent="handleScroll"
    >
        <slot />
        <div
            v-show="showScrollbarY"
            ref="scrollbarY"
            class="z-scrollbar-y"
            :class="{
                'active': activeY
            }"
            :style="{ top: scrollTop + 'px', right: -scrollLeft + 'px' }"
            @click.stop="handleScrollbarYClick"
        >
            <div
                ref="handleY"
                class="z-scrollbar-handle-y"
                :style="{ top: handleTop + 'px', height: handleHeight + 'px' }"
                @mousedown.prevent="handleYMousedown"
            />
        </div>
        <div
            v-show="showScrollbarX"
            ref="scrollbarX"
            class="z-scrollbar-x"
            :class="{
                'active': activeX
            }"
            :style="{ left: scrollLeft + 'px', bottom: -scrollTop + 'px' }"
            @click.stop="handleScrollbarXClick"
        >
            <div
                ref="handleX"
                class="z-scrollbar-handle-x"
                :style="{ left: handleLeft + 'px', width: handleWidth + 'px' }"
                @mousedown.prevent="handleXMousedown"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, nextTick, onMounted, onUnmounted } from 'vue';

export default defineComponent({
    props: {
        stopPropagation: { type: Boolean, default: false },
    },
    emits: ['scroll', 'scroll-end', 'scroll-start'],
    setup(props, context) {
        const container = ref<HTMLDivElement|null>(null);
        const scrollbarX = ref<HTMLDivElement|null>(null);
        const scrollbarY = ref<HTMLDivElement|null>(null);
        const handleX = ref<HTMLDivElement|null>(null);
        const handleY = ref<HTMLDivElement|null>(null);

        let observer;

        onMounted(() => {
            handleMutation();

            observer = new MutationObserver(handleMutation);

            observer.observe(container.value!, { childList: true, subtree: true });

            window.addEventListener('resize', handleMutation);
        });

        onUnmounted(() => {
            if (observer.disconnect) observer.disconnect();

            window.removeEventListener('resize', handleMutation);
        });

        const scrollTop = ref(0);
        const scrollLeft = ref(0);
        const scrollWidth = ref(0);
        const scrollHeight = ref(0);
        const clientWidth = ref(0);
        const clientHeight = ref(0);

        const handleWidth = computed(() => clientWidth.value * ( clientWidth.value / scrollWidth.value ));
        const handleHeight = computed(() => clientHeight.value * ( clientHeight.value / scrollHeight.value ));
        const handleLeft = computed(() => (scrollLeft.value / (scrollWidth.value - clientWidth.value)) * (clientWidth.value - (clientWidth.value * ( clientWidth.value / scrollWidth.value ))));
        const handleTop = computed(() => (scrollTop.value / (scrollHeight.value - clientHeight.value)) * (clientHeight.value - (clientHeight.value * ( clientHeight.value / scrollHeight.value ))));
        const showScrollbarX = computed(() => scrollWidth.value > clientWidth.value);
        const showScrollbarY = computed(() => scrollHeight.value > clientHeight.value);

        async function handleMutation(): Promise<void> {
            await nextTick();

            if (!container.value || !context.slots.default) return;

            //@ts-ignore
            const contentWidth = context.slots.default?.()?.reduce((sum,node) => sum + (node.elm?.clientWidth ?? 0), 0) || 1;
            //@ts-ignore
            const contentHeight = context.slots.default?.()?.reduce((sum,node) => sum + (node.elm?.clientHeight ?? 0), 0) || 1;

            if (contentHeight < container.value.clientHeight) {
                // if scrollbar content height smaller than the scrollbar component and the scrollbar handle is scrolled down, we only can hide the scrollbar with this
                scrollTop.value = 0;
            }
            else {
                scrollTop.value = container.value.scrollTop;
            }

            if (contentWidth < container.value.clientWidth) {
                // if scrollbar content height smaller than the scrollbar component and the scrollbar handle is scrolled down, we only can hide the scrollbar with this
                scrollLeft.value = 0;
            }
            else {
                scrollLeft.value = container.value.scrollLeft;
            }

            scrollWidth.value = container.value.scrollWidth;
            scrollHeight.value = container.value.scrollHeight;
            clientWidth.value = container.value.clientWidth;
            clientHeight.value = container.value.clientHeight;
        }

        function handleScroll(event): void {
            if (!event.target) return;

            scrollTop.value = event.target.scrollTop;
            scrollLeft.value = event.target.scrollLeft;
            scrollWidth.value = event.target.scrollWidth;
            scrollHeight.value = event.target.scrollHeight;
            clientWidth.value = event.target.clientWidth;
            clientHeight.value = event.target.clientHeight;

            if (event.target.scrollTop === 0) {
                context.emit('scroll-start');
            }

            if (Math.ceil(event.target.scrollTop) >= event.target.scrollHeight - event.target.clientHeight) {
                // with this we prevent infinite scrolling after reach the end
                event.target.scrollTop = event.target.scrollHeight - event.target.clientHeight;
                context.emit('scroll-end');
            }

            context.emit('scroll', { scrollTop: scrollTop.value });
        }

        function handleWheel(event: WheelEvent) {
            if (
                props.stopPropagation
                || (scrollTop.value !== 0 && event.deltaY < 0)
                || ((scrollTop.value !== scrollHeight.value - clientHeight.value) && event.deltaY > 0)
            ) {
                event.stopPropagation();
            }

            if (container.value) {
                container.value.scrollTop += event.deltaY;
                container.value.scrollLeft += event.deltaX;
            }
        }

        function handleScrollbarYClick(event: PointerEvent): void {
            const { top, bottom } = handleY.value!.getBoundingClientRect();
            if (event.clientY < top) {
                container.value!.scrollTop -= container.value!.clientHeight;
            }
            if (event.clientY > bottom) {
                container.value!.scrollTop += container.value!.clientHeight;
            }
        }

        function handleScrollbarXClick(event: PointerEvent): void {
            const { left, right } = handleX.value!.getBoundingClientRect()!;
            if (event.clientX < left) {
                container.value!.scrollLeft -= container.value!.clientWidth;
            }
            if (event.clientX > right) {
                container.value!.scrollLeft += container.value!.clientWidth;
            }
        }

        const startClientX = ref(0);
        const startClientY = ref(0);
        const startScrollLeft = ref(0);
        const startScrollTop = ref(0);
        const activeX = ref(false);
        const activeY = ref(false);

        function handleYMousemove(event: MouseEvent): void {
            const clientDeltaY = event.clientY - startClientY.value;

            container.value!.scrollTop = startScrollTop.value + clientDeltaY * scrollHeight.value / clientHeight.value;
        }

        function handleXMousemove(event: MouseEvent): void {
            const clientDeltaX = event.clientX - startClientX.value;

            container.value!.scrollLeft = startScrollLeft.value + clientDeltaX * scrollHeight.value / clientHeight.value;
        }

        function handleYMousedown(event: MouseEvent): void {
            startClientY.value = event.clientY;
            startScrollTop.value = scrollTop.value;
            activeY.value = true;

            document.addEventListener('mousemove', handleYMousemove);
            document.addEventListener('mouseup', () => {
                activeY.value = false;
                document.removeEventListener('mousemove', handleYMousemove);
            }, { once: true });
        }

        function handleXMousedown(event: MouseEvent): void {
            startClientX.value = event.clientX;
            startScrollLeft.value = scrollLeft.value;
            activeX.value = true;

            document.addEventListener('mousemove', handleXMousemove);
            document.addEventListener('mouseup', () => {
                activeX.value = false;
                document.removeEventListener('mousemove', handleXMousemove);
            }, { once: true });
        }

        function scrollToTop() {
            if (container.value) {
                container.value.scrollTop = 0;
                redraw();
            }
        }

        function redraw() {
            handleMutation();
        }

        return {
            container,
            scrollbarX,
            scrollbarY,
            handleX,
            handleY,
            handleWidth,
            handleHeight,
            handleLeft,
            handleTop,
            scrollTop,
            scrollLeft,
            showScrollbarX,
            showScrollbarY,
            activeX,
            activeY,
            handleScrollbarXClick,
            handleScrollbarYClick,
            handleScroll,
            handleXMousedown,
            handleYMousedown,
            handleWheel,
            scrollToTop,
            redraw,
        };
    },
    watch: {
        $route() {
            this.scrollToTop();
        }
    },
});
</script>


<style scoped>
    .z-scrollbar-container {
        position: relative;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .z-scrollbar-container .z-scrollbar-y {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        transition: opacity .5s, width .2s;
        width: 7px;
        user-select: none;
        z-index: 39;
    }

    .z-scrollbar-container .z-scrollbar-x {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: opacity .5s, height .2s;
        height: 7px;
        user-select: none;
        z-index: 39;
    }

    .z-scrollbar-container:hover .z-scrollbar-y,
    .z-scrollbar-container:hover .z-scrollbar-x,
    .z-scrollbar-y.active,
    .z-scrollbar-x.active {
        opacity: 1;
        transition: opacity .5s, width .2s, height .2s, background-color 1s;

    }

    .z-scrollbar-container:hover .z-scrollbar-y:hover,
    .z-scrollbar-y.active {
        width: 10px;
        transition: opacity .5s, width .2s, background-color 1s;
        background-color: #eee;
    }

    .z-scrollbar-container:hover .z-scrollbar-x:hover,
    .z-scrollbar-x.active {
        height: 10px;
        transition: opacity .5s, height .2s, background-color 1s;
        background-color: #eee;
    }

    .z-scrollbar-container::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .z-scrollbar-handle-y {
        position: absolute;
        width: 100%;
        background-color: #aaa;
        border-radius: 999px;
    }

    .z-scrollbar-handle-x {
        position: absolute;
        height: 100%;
        background-color: #aaa;
        border-radius: 999px;
    }
</style>
