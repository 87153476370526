<template>
    <ZSelect
        v-model.number="groupBy"
        class="w-40 flex-shrink-0"
    >
        <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
        >
            {{ option.label }}
        </option>
    </ZSelect>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ZSelect from '@/components/ui/Select.vue';

import { GroupByEnum } from '@/custom-labels/store/types';

export default defineComponent({
    components: {
        ZSelect
    },
    props: {
        value: { type: Number as () => GroupByEnum, required: true },
    },
    emits: ['input'],
    computed: {
        groupBy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        options() {
            return [
                { value: GroupByEnum.NO_GROUPING, label: this.$t('GROUP_BY_SELECTOR.NO_GROUPING', 'No grouping') },
                { value: GroupByEnum.BY_CATEGORY, label: this.$t('GROUP_BY_SELECTOR.GROUP_BY_CATEGORY', 'Group by category') },
                { value: GroupByEnum.BY_TAG, label: this.$t('GROUP_BY_SELECTOR.GROUP_BY_TAG', 'Group by tag') },
                { value: GroupByEnum.BY_CREATOR, label: this.$t('GROUP_BY_SELECTOR.GROUP_BY_CREATOR', 'Group by creator') },
            ];
        }
    },

});
</script>
