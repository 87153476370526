import {computed} from 'vue';
import i18next from 'i18next';
import {DimensionDefinition} from '@/domain/dataset/DatasetInterface';
import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';
import {datasetTypeId} from '@/domain/dataset/datasetTypeId';
import getDimensionDisplayName from '@/dashboard/common/utils/getDimensionDisplayName';
import accessGroupTypeEnum from '@/settings/common/accessGroupTypeEnum';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import useSubscriptionStore from '@/subscription/store';
import useUserStore from '@/user/store';
import {CommonStoreState} from '@/dashboard/common/types/commonInterface';

export default function makeGetters(state: CommonStoreState) {
    const textAnalysisDimensions = computed((): DimensionDefinition[] => state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.VERBATIM));

    const npsDimensions = computed((): DimensionDefinition[] => state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.NPS));

    const csatDimensions = computed((): DimensionDefinition[] => state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.CSAT));

    const ces5Dimensions = computed((): DimensionDefinition[] => state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.CES_5_SCALE));

    const ces7Dimensions = computed((): DimensionDefinition[] => state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.CES_7_SCALE));

    const isSurvey = computed(() => state.dataset.type === datasetTypeId.survey);

    const isEmail = computed(() => state.dataset.type === datasetTypeId.email);

    const dimensionUserFriendlyName = computed(() => {
        if ([datasetTypeId.excel, datasetTypeId.website_crawler, datasetTypeId.pdf_extractor].includes(state.dataset.type)) {
            return i18next.t('DASHBOARD.FILTER_TYPE_COLUMN', 'Column');
        } else if (state.dataset.type === datasetTypeId.survey) {
            return i18next.t('DASHBOARD.FILTER_TYPE_QUESTION', 'Question');
        } else {
            return i18next.t('DASHBOARD.FILTER_TYPE_PARAM', 'Parameter');
        }
    });

    const filterableMetaDimensions = computed(() => state.dataset.dimension_definitions
        .filter(d => d.is_metadata && [dimensionTypeId.CATEGORY, dimensionTypeId.NUMBER].includes(d.type))
        .map(d => { return { ...d, name: getDimensionDisplayName(d.name)};})
    );

    const filterableParameterDimensions = computed(() => state.dataset.dimension_definitions
        .filter(d => !d.is_metadata && !d.is_visible && [dimensionTypeId.CATEGORY].includes(d.type))
        .map(d => { return { ...d, name: getDimensionDisplayName(d.name)};})
    );

    const filterableCampaignRelatedDimensions = computed(() => state.dataset.dimension_definitions.filter(d =>
        d.type === dimensionTypeId.CAMPAIGN_ID
    ).map(c => ({ ...c, name: i18next.t('DASHBOARD.FILTER_TYPE_CAMPAIGN', 'Campaign') }) ));

    const filterableDimensions = computed(() => state.dataset.dimension_definitions.filter(d => {
        if(state.dataset.type == datasetTypeId.nmi && d.id === 'sub_source') {
            return false;
        }

        const filterableDimensionTypes = [
            dimensionTypeId.CATEGORY,
            dimensionTypeId.MULTI_VALUE,
            dimensionTypeId.NUMBER,
            dimensionTypeId.GROUPED_MULTI_VALUE,
            dimensionTypeId.GROUPED_CATEGORY,
            dimensionTypeId.NPS,
            dimensionTypeId.CSAT,
            dimensionTypeId.CES_7_SCALE,
            dimensionTypeId.CES_5_SCALE,
            dimensionTypeId.URL,
            dimensionTypeId.NON_UNIQUE_ID
        ];

        // if it is not initialized, then the common store used by dataset alert popup, where date dimensions are not needed
        if(state.initialized) {
            filterableDimensionTypes.push(dimensionTypeId.DATE);
        }
        return !d.is_metadata && d.is_visible && filterableDimensionTypes.includes(d.type);
    }));

    const permissionLevel = computed(() => {
        if (useSubscriptionStore().getters.canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP)) {
            return state.dataset.permissionLevel;
        }
        return accessGroupTypeEnum.EDITOR;
    });

    const canEdit = computed(() => {
        if (useSubscriptionStore().getters.canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP)) {
            return state.dataset.permissionLevel >= accessGroupTypeEnum.EDITOR;
        }
        return !useUserStore().state.isGuestUser;
    });

    const canExport = computed(() => {
        if (useSubscriptionStore().getters.canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP)) {
            return state.dataset.permissionLevel >= accessGroupTypeEnum.EXPORTER;
        }
        return !useUserStore().state.isGuestUser;
    });

    const canView = computed(() => {
        if (useSubscriptionStore().getters.canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP)) {
            return state.dataset.permissionLevel >= accessGroupTypeEnum.VIEWER;
        }
        return true;
    });

    const isAdmin = computed(() => {
        return useUserStore().getters.isAdmin.value;
    });

    return {
        textAnalysisDimensions,
        npsDimensions,
        csatDimensions,
        isSurvey,
        isEmail,
        dimensionUserFriendlyName,
        filterableMetaDimensions,
        filterableParameterDimensions,
        filterableDimensions,
        permissionLevel,
        canEdit,
        canExport,
        canView,
        isAdmin,
        ces5Dimensions,
        ces7Dimensions,
        filterableCampaignRelatedDimensions
    };
}
