import {computed} from 'vue';

import {UserState} from './types';

export default function makeGetters(state: UserState) {
    const primaryColor = computed(() => state.primaryColor || '#1cc6ba');
    // TODO: remove getters
    const canCreateDatasets = computed(() => {
        return state.canCreateDatasets;
    });

    const canManageCustomLabels = computed(() => {
        return state.canManageCustomLabels;
    });

    const isAdmin = computed(() => {
        return state.isAdmin;
    });

    const isSuperAdmin = computed(() => {
        return state.isSuperAdmin;
    });

    const noFlags = computed(() => {
        return (
            !isSuperAdmin.value
            && !isAdmin.value
            && !canManageCustomLabels.value
            && !canCreateDatasets.value
        );
    });

    return {
        primaryColor,
        canCreateDatasets,
        canManageCustomLabels,
        isAdmin,
        isSuperAdmin,
        noFlags,
    };
}
