import PQueue from 'p-queue';
import api from '@/api';
import * as customLabelsApi from '@/custom-labels/api/custom-labels';
import { customLabelReanalyses, getCustomLabelReanalyses } from '@/dashboard/api/datasetApi';
import useCustomLabelStore from '@/custom-labels/store/index';
import useCommonStore from '@/dashboard/common/store/commonStore';
import { setLoading } from '@/utils/loading';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import { CustomLabel, PhraseSet } from '@/custom-labels/store/types';
import langEnum from '@/domain/analysisLanguageEnum.js';

interface AddedPhrase {
    phrase: string,
    customLabelId: number,
    languageId?: number,
}

export const initState = {
    showAddSynonymPopup: false,
    addSynonymPopupSettings: {
        sortBy: 'name' as 'name'|'category'|'synonyms',
        sortDir: 'asc' as 'asc'|'desc',
        filter: {
            type: null as null|'tag'|'category'|'label',
            value: null as null|string,
        },
    },
    selectedCustomLabelId: null as number|null,
    selectedText: '',
    addSynonymOrExcludedPhraseMode: null as 'synonym'|'exclude'|null,
    phraseLanguageId: null as number|null,
    addedSynonyms: [] as AddedPhrase[],
    addedExcludeds: [] as AddedPhrase[],
    newCustomLabelPhrases: [] as Array<{ phrase: string, langId: langEnum }>,
    showNewCustomLabelPopup: false,
    newCustomLabels: [] as any[],
    editCustomLabelSetup: null as any|null,
    lastReanalysisData: null as any|null,
    activeReanalysis: false,
    reanalysisIsFinished: false,
    selectedNewCustomLabelIndex: null as number|null,
};

export function makeActions(state: typeof initState & { columnId: string|null }) {
    return {
        setShowAddSynonymPopup: (show: boolean) => state.showAddSynonymPopup = show,
        setSelectedCustomLabelId: (id: number | null) => state.selectedCustomLabelId = id,
        setSelectedText: (text: string) => state.selectedText = text,
        setPhraseLanguageId: (id: number) => state.phraseLanguageId = id,
        setNewCustomLabelPhrases: (phrases: Array<{ phrase: string, langId: langEnum }>) => state.newCustomLabelPhrases = phrases,
        setAddSynonymOrExcludedPhraseMode: (mode: 'synonym'|'exclude') => state.addSynonymOrExcludedPhraseMode = mode,
        addSynonymOrExcludedPhrase: async () => {
            if (state.addSynonymOrExcludedPhraseMode === 'synonym') {
                const filteredPhrases = state.newCustomLabelPhrases.filter(phrase => !state.addedSynonyms.some(s => s.phrase === phrase.phrase && s.customLabelId === state.selectedCustomLabelId));

                const customLabelId = state.selectedCustomLabelId!;
                const suggestedSynomyms: any[] = [];

                try {
                    await Promise.all(filteredPhrases.map(async (phrase) => {
                        const synonyms = await customLabelsApi.getSynonymSuggestions({
                            words: phrase.phrase,
                            languageId: phrase.langId,
                            type: 'synonym'
                        });

                        suggestedSynomyms.push(...(synonyms.map(s => ({ phrase: s, langId: phrase.langId }))));
                    }));
                }
                catch (e) {
                    Sentry.captureException(e);

                    // if suggest throws error, just add the original phrases
                    filteredPhrases.forEach(p => {
                        state.addedSynonyms.push({
                            phrase: p.phrase,
                            customLabelId,
                            languageId: p.langId,
                        });
                    });

                    return;
                }

                suggestedSynomyms.forEach(s => {
                    state.addedSynonyms.push({
                        phrase: s.phrase,
                        customLabelId,
                        languageId: s.langId,
                    });
                });
            }
            else {
                state.newCustomLabelPhrases.forEach(p => {
                    state.addedExcludeds.push({
                        phrase: p.phrase,
                        customLabelId: state.selectedCustomLabelId!,
                    });
                });
            }
        },
        addSynonymOrExcludedPhraseToNewCustomLabel: async () => {
            const customLabel = state.newCustomLabels[state.selectedNewCustomLabelIndex!];

            await Promise.all(state.newCustomLabelPhrases.map(async (p) => {
                const phraseSet = customLabel.phraseSets.find(ps => ps.languageId == p.langId);

                const suggestedSynonyms: string[] = [];

                if (state.addSynonymOrExcludedPhraseMode === 'synonym') {
                    try {
                        const synonyms = await customLabelsApi.getSynonymSuggestions({
                            words: [p.phrase],
                            languageId: p.langId,
                            type: 'synonym'
                        });

                        suggestedSynonyms.push(...synonyms);
                    }
                    catch (e) {
                        Sentry.captureException(e);

                        // if suggest throws error, just add the original phrase
                        suggestedSynonyms.push(p.phrase);
                    }
                }

                if (phraseSet) {
                    if (state.addSynonymOrExcludedPhraseMode === 'synonym') {
                        phraseSet.synonyms = [...phraseSet.synonyms, ...suggestedSynonyms];
                    } else {
                        phraseSet.excludes = [...phraseSet.excludes, p.phrase];
                    }
                }
                else {
                    if (state.addSynonymOrExcludedPhraseMode === 'synonym') {
                        customLabel.phraseSets.push({
                            languageId: p.langId,
                            synonyms: suggestedSynonyms,
                            excludes: [],
                        });
                    }
                    else {
                        customLabel.phraseSets.push({
                            languageId: p.langId,
                            synonyms: [],
                            excludes: [p.phrase],
                        });
                    }
                }
            }));
        },
        deleteSynonymOrExcludedPhrase: ({ type, phrase, clId }: {type: 'synonym' | 'excluded', phrase: string, clId: number}) => {
            if (type === 'synonym') {
                state.addedSynonyms = state.addedSynonyms.filter(synonym => synonym.phrase !== phrase || synonym.customLabelId !== clId);
            } else {
                state.addedExcludeds = state.addedExcludeds.filter(excluded => excluded.phrase !== phrase || excluded.customLabelId !== clId);
            }
        },
        resetAddSynonymPopup: () => {
            state.showAddSynonymPopup = false;
            state.selectedCustomLabelId = null;
            state.selectedText = '';
            state.addSynonymOrExcludedPhraseMode = null;
            state.addSynonymPopupSettings = {
                sortBy: 'name',
                sortDir: 'asc',
                filter: {
                    type: null as null|'tag'|'category'|'label',
                    value: null as null|string,
                },
            };
            state.selectedNewCustomLabelIndex = null;
            state.phraseLanguageId = null;
            state.newCustomLabelPhrases = [];
        },
        resetQuickReanalysisStore: () => {
            state.addedSynonyms = [];
            state.addedExcludeds = [];
            state.newCustomLabels = [];
            state.lastReanalysisData = null;
            state.activeReanalysis = false;
            state.reanalysisIsFinished = false;
        },
        setSort: (by: 'name'|'category'|'synonyms') => {
            if (by === state.addSynonymPopupSettings.sortBy) {
                state.addSynonymPopupSettings.sortDir = state.addSynonymPopupSettings.sortDir === 'asc' ? 'desc' : 'asc';
            } else {
                state.addSynonymPopupSettings.sortBy = by;
                state.addSynonymPopupSettings.sortDir = 'asc';
            }
        },
        setShowNewCustomLabelPopup: (show: boolean) => {
            state.showNewCustomLabelPopup = show;
        },
        addNewCustomLabel: (cl: any) => {
            state.newCustomLabels.push(cl);
        },
        deleteNewCustomLabel: (index: number) => {
            state.newCustomLabels.splice(index, 1);
        },
        editNewCustomLabel: (index: number) => {
            const customLabel = state.newCustomLabels[index];

            state.editCustomLabelSetup = {
                index,
                customLabel,
            };
            state.showNewCustomLabelPopup = true;
        },
        resetEditCustomLabelSetup: () => {
            state.editCustomLabelSetup = null;
        },
        saveNewCustomLabelEdit: (cl: any) => {
            state.newCustomLabels.splice(state.editCustomLabelSetup.index, 1, cl);
        },
        startReanalize: async () => {
            const { state: customLabelState } = useCustomLabelStore();
            const { state: commonState } = useCommonStore();
            const datasetLanguageId = commonState.dataset.language_id;

            const queue = new PQueue({ concurrency: 1 });

            const newClIds: number[] = [];
            const updatedClIds: number[] = [];

            setLoading(true);

            // modify existing custom labels

            const customLabels: { [clId: number]: {
                synonyms: Array<{ phrase: string, languageId?: number }>,
                excludes: Array<{ phrase: string, languageId?: number }>,
            } } = {};

            state.addedSynonyms.forEach((synonym: AddedPhrase) => {
                if (!customLabels[synonym.customLabelId]) {
                    customLabels[synonym.customLabelId] = {
                        synonyms: [],
                        excludes: [],
                    };
                }
                customLabels[synonym.customLabelId].synonyms.push({
                    phrase: synonym.phrase,
                    languageId: synonym.languageId
                });
            });

            state.addedExcludeds.forEach((excluded: AddedPhrase) => {
                if (!customLabels[excluded.customLabelId]) {
                    customLabels[excluded.customLabelId] = {
                        synonyms: [],
                        excludes: [],
                    };
                }
                customLabels[excluded.customLabelId].excludes.push({
                    phrase: excluded.phrase,
                    languageId: excluded.languageId
                });
            });

            Object.entries(customLabels).forEach(([clId, { synonyms, excludes }]) => {
                const customlabel = customLabelState.customLabels.find(cl => cl.id === +clId)! as CustomLabel;

                synonyms.forEach(synonym => {
                    const phraseSet = customlabel.phraseSets.find(ps => ps.languageId == (synonym.languageId || datasetLanguageId));

                    if (phraseSet) {
                        phraseSet.synonyms = [...phraseSet.synonyms, synonym.phrase];
                    } else {
                        customlabel.phraseSets = [...customlabel.phraseSets, {
                            languageId: synonym.languageId || datasetLanguageId,
                            synonyms: [synonym.phrase],
                            excludes: []
                        } as PhraseSet];
                    }
                });

                excludes.forEach(excluded => {
                    const phraseSet = customlabel.phraseSets.find(ps => ps.languageId == (excluded.languageId || datasetLanguageId));

                    if (phraseSet) {
                        phraseSet.excludes = [...phraseSet.excludes, excluded.phrase];
                    } else {
                        customlabel.phraseSets = [...customlabel.phraseSets, {
                            languageId: excluded.languageId || datasetLanguageId,
                            synonyms: [],
                            excludes: [excluded.phrase]
                        } as PhraseSet];
                    }
                });

                queue.add(() => {
                    try {
                        Sentry.addBreadcrumb({
                            message: 'adding synonym',
                            data: {
                                customLabelId: clId,
                                synonyms,
                                excludes,
                            },
                        });
                        customLabelsApi.updateCustomLabel(customlabel.id, customlabel);
                        updatedClIds.push(customlabel.id);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                });
            });

            // create new custom labels
            state.newCustomLabels.forEach(cl => {
                queue.add(async () => {
                    try {
                        Sentry.addBreadcrumb({
                            message: 'Creating new custom label',
                            data: { customlabel: cl },
                        });
                        const response = await customLabelsApi.createCustomLabel(cl);

                        Sentry.addBreadcrumb({
                            message: 'New custom label created',
                            data: { customLabelId: response.id }
                        });
                        newClIds.push(response.id);
                    } catch (error) {
                        Sentry.captureException(error);
                    }
                });
            });

            queue.onIdle().then(async () => {
                const datasetId = commonState.dataset.id;
                const guid = state.columnId!;

                const originalCustomLabels = commonState.dataset.dimension_definitions.find(def => def.id === guid)?.customLabels || [];
                const allCustomLabelIds = [...new Set([...originalCustomLabels, ...newClIds])];
                // only check new and updated CLs without duplication during CL quick reanalysis
                const modifiedCustomLabelIds = [...new Set([...updatedClIds, ...newClIds])];

                try {
                    await api.setCustomLabelIds(datasetId, guid, allCustomLabelIds);
                    await customLabelReanalyses(datasetId, guid, modifiedCustomLabelIds);

                    state.activeReanalysis = true;

                    state.addedSynonyms = [];
                    state.addedExcludeds = [];
                    state.newCustomLabels = [];
                } catch (error) {
                    Sentry.captureException(error);
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                }

                setLoading(false);
            });
        },
        getReanalysisStatus: async () => {
            const { state: commonState } = useCommonStore();
            try {
                const reanalyses = await getCustomLabelReanalyses(commonState.dataset.id);

                if (reanalyses.length === 0) {
                    state.lastReanalysisData = null;
                    return;
                }
                state.lastReanalysisData = reanalyses.findLast(r => r.dimension_id === state.columnId);

                if (state.activeReanalysis && (state.lastReanalysisData.non_analyzable_records + state.lastReanalysisData.analyzed_records === state.lastReanalysisData.all_records || state.lastReanalysisData.finished_at !== null)) {
                    state.reanalysisIsFinished = true;
                }

                state.activeReanalysis = (state.lastReanalysisData.non_analyzable_records + state.lastReanalysisData.analyzed_records < state.lastReanalysisData.all_records) && state.lastReanalysisData.finished_at === null;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        setActiveReanalysis: (active: boolean) => {
            state.activeReanalysis = active;
        },
        setSelectedNewCustomLabelIndex: (index: number|null) => {
            state.selectedNewCustomLabelIndex = index;
        },
    };
}
