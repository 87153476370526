<template>
    <div
        v-tooltip="getTooltipText"
        class="inline-flex items-center text-small rounded px-1 py-1 ml-1 mb-2 cursor-pointer"
        :class="opinionClasses"
    >
        <div @click="noEditPopup ? drillDown(entity) : $emit('edit') ">
            <icon
                v-if="entity.subtype === 'manual_custom_label'"
                icon="user-tag"
                :type="notSolidIcon ? 'fal' : 'fas'"
                class="mx-1"
            />
            <icon
                v-else-if="entity.type === 'custom_label'"
                icon="tag"
                type="fas"
                class="mx-1"
            />
            <span v-if="entity.category">{{ entity.category }} / </span>
            <span>{{ entity.name }}</span>
            <span
                v-if="labelTranslationEnabled && entity.engName"
                class="pl-1"
            >
                ({{ entity.engName }})
            </span>
        </div>
        <div class="ml-1 flex">
            <div
                v-if="canEdit && canEditLabels"
                class="rounded-l w-4 h-4 p-1 items-center justify-center text-bg"
                :class="{
                    'hidden group-hover:flex': !alwaysShowButtons,
                    'flex': alwaysShowButtons,
                    'bg-red-600': entity.opinionIndex < 0,
                    'bg-green-600': entity.opinionIndex > 0,
                    'bg-neutral-700': entity.opinionIndex === 0
                }"
                data-test-id="increase-score"
                @click="$emit('change-oi', { id: entity.id, subtype: entity.subtype, value: Math.floor(Number(entity.opinionIndex)) - 1 })"
            >
                <icon icon="minus" />
            </div>
            <div
                class="flex items-center justify-center w-4 h-4 p-1 text-bg"
                :class="{
                    'rounded group-hover:rounded-none': canEdit && canEditLabels && !alwaysShowButtons,
                    'bg-red-500': entity.opinionIndex < 0,
                    'bg-green-500': entity.opinionIndex > 0,
                    'bg-neutral-600': entity.opinionIndex === 0
                }"
            >
                {{ entity.opinionIndex }}
            </div>
            <div
                v-if="canEdit && canEditLabels"
                class="rounded-r w-4 h-4 pb-px items-center justify-center text-bg"
                :class="{
                    'hidden group-hover:flex': !alwaysShowButtons,
                    'flex': alwaysShowButtons,
                    'bg-red-600': entity.opinionIndex < 0,
                    'bg-green-600': entity.opinionIndex > 0,
                    'bg-neutral-700': entity.opinionIndex === 0
                }"
                data-test-id="increase-score"
                @click="$emit('change-oi',{ id: entity.id, subtype: entity.subtype, value: Math.floor(Number(entity.opinionIndex)) + 1 })"
            >
                <icon icon="plus" />
            </div>
            <div
                v-if="canEdit && canEditLabels"
                class="items-center justify-center w-4 h-4 p-1 ml-1 rounded text-bg"
                :class="{
                    'hidden group-hover:flex': !alwaysShowButtons,
                    'flex': alwaysShowButtons,
                    'bg-red-600': entity.opinionIndex < 0,
                    'bg-green-600': entity.opinionIndex > 0,
                    'bg-neutral-700': entity.opinionIndex === 0
                }"
                @click="$emit('remove-label', { id: entity.id, subtype: entity.subtype })"
            >
                <icon
                    icon="times"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import i18next from 'i18next';
import { computed, defineComponent, toRefs } from 'vue';
import useCommonStore from '@/dashboard/common/store/commonStore';
import Icon from '@/components/ui/Icon.vue';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import useSubscriptionStore from '@/subscription/store';
import useUserStore from '@/user/store';
import { DrillDownSettings } from '@/dashboard/common/store/drillDown';

export default defineComponent({
    components: { Icon },
    props: {
        entity: {
            type: Object as () => {id: number, name: string, type: string, subtype?: string, opinionIndex: number, engName: string, mentionNumber: number, category?: string},
            required: true
        },
        disabled: { type: Boolean, default: false },
        noDrilldown: { type: Boolean, default: false },
        notSolidIcon: { type: Boolean, default: false },
        alwaysShowButtons: { type: Boolean, default: false },
        noEditPopup: { type: Boolean, default: true },
    },
    setup(props, context) {

        const { labelTranslationEnabled } = toRefs(useCommonStore().state);
        const { canEdit } = useCommonStore().getters;
        const { canUseFeature } = useSubscriptionStore().getters;
        const { canManageCustomLabels } = toRefs(useUserStore().state);

        const canEditLabels = computed (() => (canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP) ? canEdit : canManageCustomLabels) && !props.disabled);

        const opinionClasses = computed(() => {
            if (props.entity.opinionIndex < 0) {
                return 'text-red-900 bg-red-100';
            } else if (props.entity.opinionIndex > 0) {
                return 'text-green-900 bg-green-200';
            }
            return 'text-neutral-900 bg-neutral-400';
        });

        const getTooltipText = () => {
            return '<b>' + props.entity.name + '</b>' +
                (labelTranslationEnabled.value && props.entity.engName ? ' (' + props.entity.engName + ')' : '') + '<br>' +
                i18next.t('DASHBOARD.SUMMARY.MENTIONS') + ': ' + props.entity.mentionNumber + ', ' +
                i18next.t('DASHBOARD.LEFTBAR.POLARITY') + ': '+ props.entity.opinionIndex;
        };

        const drillDown = (entity) => {
            if (props.noDrilldown) return;

            const settings: DrillDownSettings = {
                type: entity.type === 'custom_label' ? 'customLabel' : 'label',
                value: {
                    name: entity.name,
                    type: entity.type === 'custom_label' ? 'customLabel' : 'label',
                    id: entity.id
                },
            };
            context.emit('drill-down', settings);
        };

        return { labelTranslationEnabled, opinionClasses, getTooltipText, canEdit, canEditLabels, drillDown };
    },
});
</script>
