<template>
    <div
        class="bg-bg shadow mt-3 rounded p-3"
    >
        <div
            class="cursor-pointer flex h-4"
            @click="isCustomLabelsOpen = !isCustomLabelsOpen"
        >
            <h3 class="text-sm mb-1 font-bold">
                {{ $t('ANALYSIS.KEYWORDS') }}
            </h3>
            <span class="ml-1 text-neutral-800 text-xs">{{ customLabelHelpText }}</span>
            <z-icon
                v-tooltip.top="$t('ANALYSIS.KEYWORDS_TOOLTIP')"
                icon="info-circle"
                class="text-sm text-black ml-1"
            />
            <div
                class="flex-grow text-right"
            >
                <z-icon
                    :icon="isCustomLabelsOpen ? 'angle-up' : 'angle-down'"
                    data-test-id="custom-label-settings"
                    class="text-base text-black ml-1"
                />
            </div>
        </div>
        <keep-alive>
            <custom-label-editor
                v-if="isCustomLabelsOpen"
                :selected-label-ids="selectedLabelIds"
                :language-id="languageId"
                class="mt-4"
                @update:selected-label-ids="$emit('update:selected-label-ids', $event)"
            />
        </keep-alive>
        <div
            v-if="!isCustomLabelsOpen && error"
            class="text-small my-3 text-red-500"
        >
            <z-icon icon="exclamation-triangle" /> {{ $t('CUSTOM_LABEL_EDITOR.LANGUAGE_WARNING', 'Some Custom Labels do not include the Analysis language as its Language. Click on the Custom Label to edit, or remove it to proceed.') }}
        </div>
    </div>
</template>

<script lang="ts">
import CustomLabelEditor from './CustomLabelEditor.vue';
import { computed, ref } from 'vue';
import i18next from 'i18next';
import useCustomLabelStore from '@/custom-labels/store/';
import ZIcon from '@/components/ui/Icon.vue';

export default {
    name: 'CustomLabelSettingsPanel',
    components: {
        CustomLabelEditor,
        ZIcon,
    },
    model: {
        prop: 'selectedLabelIds',
        event: 'update:selected-label-ids'
    },
    props: {
        selectedLabelIds: { type: Array as () => Array<number>, default: () => ([]) },
        languageId: { type: Number, required: true },
        error: { type: Boolean, default: false },
    },
    setup() {
        const isCustomLabelsOpen = ref(false);
        const { state } = useCustomLabelStore();
        const numberOfLabels = computed(() => state.customLabels.length );
        const customLabelHelpText = computed(() => {
            return i18next.t('ANALYSIS.CUSTOM_LABEL_HELP_TEXT', {
                numberOfLabels: numberOfLabels.value,
                defaultValue: 'You have {{numberOfLabels}} Custom Labels created, you can add them here.'
            });
        });
        return {
            isCustomLabelsOpen,
            customLabelHelpText
        };
    }
};
</script>
