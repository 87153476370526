import debounce from 'lodash/debounce';
import i18next from 'i18next';
import api from '@/api';
import gtm from '@/utils/gtm';
import globals from '@/utils/globals';
import { setIsMultiLang, saveTranslations, getSurveyQuestionOptions, saveOptionsFromFile, deleteOptionsFromFile, getOptionsFromFileCount } from '../api/index';
import { isEqual, cloneDeep } from 'lodash';
import { actionEnum } from '@/survey/store/utils/conditionEnum';
import elementTypes from '@/survey/store/utils/elementTypeEnum';
import { getNicknameFromId, getIdFromNickname } from '@/domain/analysisLanguageEnum';
import { produce } from 'immer';
import nullifyDropdownOptions from './utils/nullifyDropdownOptions';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import striptags from 'striptags';

import PQueue from 'p-queue';
const questionSavingQueue = new PQueue({ concurrency: 1 });

const removeHtmlFromString = (string) => string.replace(/<\/?[^>]+(>|$)/g, '');

export default {
    async init({ dispatch, state, getters }) {
        await Promise.all([
            dispatch('getMetaData'),
            dispatch('getElements'),
            dispatch('getSemanticSettings'),
            dispatch('getDisqualificationPages'),
            dispatch('getSelectedCustomLabels'),
        ]);
        state.initialized = true;
        state.saveInterval = setInterval(() => {
            const currentState = JSON.stringify({
                title: state.title,
                description: state.description,
                working_title: state.working_title,
                thank_you_title: state.thank_you_title,
                thank_you_description: state.thank_you_description,
                thank_you_image_url: state.thank_you_image_url,
                thank_you_logo_url: state.thank_you_logo_url,
                thank_you_logo: state.thank_you_logo,
                primary_color: state.primary_color,
                text_color: state.text_color,
                background_color: state.background_color,
                title_font_name: state.title_font_name,
                title_font_weight: state.title_font_weight,
                title_font_size: state.title_font_size,
                question_font_name: state.question_font_name,
                question_font_weight: state.question_font_weight,
                question_font_size: state.question_font_size,
                answer_font_name: state.answer_font_name,
                answer_font_weight: state.answer_font_weight,
                answer_font_size: state.answer_font_size,
                layout_spacing: state.layout_spacing,
                language_id: state.language_id,
                accent: state.accent,
                lowercase: state.lowercase,
                textSplitting: state.textSplitting,
                autoTranslation: state.autoTranslation,
                autoTranslationWithHtml: state.autoTranslationWithHtml,
                is_question_ordinal_number_visible: state.is_question_ordinal_number_visible,
                question_tag_type: state.question_tag_type,
                is_filling_time_visible: state.is_filling_time_visible,
                has_own_privacy_policy: state.hasOwnPrivacyPolicy,
                privacy_policy_title: state.privacyPolicyTitle,
                privacy_policy_url: state.privacyPolicyUrl,
                is_autopage_enabled: state.isAutoPaginationOn,
                filling_time: state.fillingTime,
                bg_image: state.bgImage,
                is_browser_tab_title_enabled: state.is_browser_tab_title_enabled,
                is_filling_progress_visible: state.is_filling_progress_visible,
                filling_progress_position: state.filling_progress_position,
                logo_settings: state.logo_settings,
                thankYouRedirect: state.thankYouRedirect,
            });
            if (!state.prevState) {
                state.prevState = currentState;
            } else if (state.prevState !== currentState) {
                dispatch('save');
                state.prevState = currentState;
            }

            if (!state.savedElements) {
                state.savedElements = JSON.parse(JSON.stringify(state.elements));
            } else if (getters.isAutoPublishOn && JSON.stringify(state.savedElements) !== JSON.stringify(state.elements)) {
                dispatch('saveElements');
                state.savedElements = JSON.parse(JSON.stringify(state.elements));
            }
        }, 100);
    },
    save: debounce(async function ({ dispatch }) {
        await dispatch('saveNow');
    }, globals.getSurveySyncTimeout()),
    saveElements: debounce(function ({ dispatch }) {
        dispatch('saveElementsNow');
    }, globals.getSurveySyncTimeout()),
    async saveNow({ state, dispatch }) {
        await api.survey.syncSurvey({
            id: state.id,
            title: state.title,
            description: state.description,
            working_title: state.working_title,
            thank_you_title: state.thank_you_title,
            thank_you_description: state.thank_you_description,
            thank_you_image_url: state.thank_you_image_url,
            thank_you_logo_url: state.thank_you_logo_url,
            thank_you_logo: state.thank_you_logo,
            primary_color: state.primary_color,
            text_color: state.text_color,
            background_color: state.background_color,
            title_font_name: state.title_font_name,
            title_font_weight: state.title_font_weight,
            title_font_size: state.title_font_size,
            question_font_name: state.question_font_name,
            question_font_weight: state.question_font_weight,
            question_font_size: state.question_font_size,
            answer_font_name: state.answer_font_name,
            answer_font_weight: state.answer_font_weight,
            answer_font_size: state.answer_font_size,
            layout_spacing: state.layout_spacing,
            language_id: state.language_id,
            accent: state.accent,
            lowercase: state.lowercase,
            textSplitting: state.textSplitting,
            autoTranslation: state.autoTranslation,
            autoTranslationWithHtml: state.autoTranslationWithHtml,
            is_question_ordinal_number_visible: state.is_question_ordinal_number_visible,
            question_tag_type: state.question_tag_type,
            is_filling_time_visible: state.is_filling_time_visible,
            has_own_privacy_policy: state.hasOwnPrivacyPolicy,
            privacy_policy_title: state.privacyPolicyTitle,
            privacy_policy_url: state.privacyPolicyUrl,
            uniqueName: state.uniqueName,
            status: state.status,
            is_autopage_enabled: state.isAutoPaginationOn,
            filling_time: state.fillingTime,
            bg_image: state.bgImage,
            is_browser_tab_title_enabled: state.is_browser_tab_title_enabled,
            is_filling_progress_visible: state.is_filling_progress_visible,
            filling_progress_position: state.filling_progress_position,
            logo_settings: state.logo_settings,
            thank_you_redirect: state.thankYouRedirect,
        });
        await dispatch('syncTranslations', { title: state.title, description: state.description, thank_you_title: state.thank_you_title, thank_you_description: state.thank_you_description });
    },
    async saveElementsNow({ state, dispatch, getters }) {
        state.savedElements = JSON.parse(JSON.stringify(state.elements));
        // Queue should be cleared in order to only save the most recent state of elements
        questionSavingQueue.clear();
        questionSavingQueue.add(async () => {
            try {
                if (state.hasNewDropdownOptionsStoring) {
                    await api.survey.saveQuestions(getters.elementsWithoutFileUploadedOptions, state.id);
                } else {
                    await api.survey.saveQuestions(state.elements, state.id);
                }
            }
            catch (e) {
                Sentry.captureException(e);
            }
        });

        await questionSavingQueue.onIdle(); // wait until save finished (needed if dropdown with options from file are added newly)
        if (state.pending.pendingOptionsFromFile.length > 0) {
            await dispatch('survey/pending/savePendingOptionsFromFile', null, { root: true });
        }

        await dispatch('syncTranslations', { questions: state.elements });
    },
    saveDisqualificationPages: debounce(async function ({ state, dispatch, commit }) {
        const disqualificationPages = await api.survey.saveDisqualifications({ id: state.id, disqualificationPages: state.disqualificationPages });
        commit('setDisqualificationPages', disqualificationPages);
        await dispatch('syncTranslations', { disqualificationPages: disqualificationPages });
    }, 2000),
    async getDisqualificationPages({ commit, state }) {
        const response = await api.survey.getDisqualifications(state.id);
        commit('setDisqualificationPages', response);
    },
    async getElements({ commit, state }) {
        const response = await api.survey.getElements(state.id);
        commit('setElements', response);
    },
    async getSemanticSettings({ commit, state }) {
        const response = await api.survey.getSemanticSettings(state.id);
        commit('setAccent', response[0]);
        commit('setLowercase', response[1]);
        commit('setTextSplittingType', response[2]);
        commit('setAutoTranslation', response[3]);
        commit('setAutoTranslationWithHtml', response[4]);
    },
    async getMetaData({ commit, state }) {
        const response = await api.survey.getMetaData(state.id);
        commit('setMetaData', response);
    },
    async getSelectedCustomLabels({ state, commit }) {
        let selectedCustomLabels;
        try {
            selectedCustomLabels = await api.survey.getSelectedCustomLabels(state.id);
        } catch (e) {
            selectedCustomLabels = [];
        }
        commit('setSelectedCustomLabels', [...selectedCustomLabels]);
        commit('customLabels/setOriginalSelectedCustomLabels', [...selectedCustomLabels]);
    },
    async updateSelectedCustomLabels({ state, commit }, { customLabelIds, guid }) {
        await api.setCustomLabelIds(state.datasetId, guid, customLabelIds);
        commit('updateSelectedCustomLabels', { guid, customLabelIds });
    },
    async uploadPicture({ commit, state }, { guid, file }) {
        const response = await api.survey.saveItemPicture({ guid, file, surveyId: state.id });
        commit('setPicture', { guid, picture_filename: response.fileName, picture_url: response.url });
    },
    async deletePicture({ commit, state }, guid) {
        await api.survey.deleteApiPicture(guid, state.id);
        commit('removePicture', guid);
    },
    async uploadFinishPicture({ commit, state }, { order, file }) {
        const response = await api.survey.saveFinishPicture({ order, file, surveyId: state.id });
        commit('setFinishPagePicture', { order, url: response.url, fileName: response.fileName });
    },
    async deleteFinishPicture({ commit, state }, order) {
        await api.survey.deleteFinishPicture(order, state.id);
        commit('removeFinishPagePicture', { order });
    },
    async updateThankYouLogo({ dispatch, state }, file) {
        await api.survey.saveThankYouLogo(state.id, file);
        dispatch('getMetaData');
    },
    async updateDisqPageLogo({ dispatch, state }, { disqPageId, file }) {
        await api.survey.saveDisqualificationPageLogo(state.id, disqPageId, file);
        dispatch('getDisqualificationPages');
    },
    async savePictureChoiceOption({ commit }, { element, file, index }) {
        const response = await api.survey.savePictureChoiceOption({ guid: element.guid, file, index });

        const option = typeof element.options[index] === 'string' ? element.options[index] : element.options[index].value;

        commit('updateOption', {
            guid: element.guid,
            index: index,
            value: option ? option : i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_PICTURE_CAPTION') + ' ' + (index + 1),
            file: response
        });
    },
    async syncLogo( { commit, state, dispatch }, { source } ) {
        const response = source ?
            await api.survey.updateLogo({ id: state.id, source }) :
            await api.survey.deleteLogo(state.id);
        dispatch('getDisqualificationPages');

        commit('setMetaData', {
            thank_you_logo: response.thank_you_logo,
            thank_you_logo_url: response.thank_you_logo_url
        });
        if (response.logo_file_url && response.logo_source_url) {
            commit('setMetaData', {
                logo_file_url: response.logo_file_url,
                logo_source_url: response.logo_source_url,
            });
        } else {
            commit('resetLogo');
        }

        gtm.track(gtm.events.SURVEY_LOGO_UPDATED, gtm.categories.SURVEY_LOGO_UPDATED, { id: state.id });
    },
    async setAnswer() {},
    async changeStatus({ state, commit }, status) {
        const statusCode = status ? 1 : 2;
        commit('changeStatus', statusCode);
        await api.survey.changeStatus(state.id, status);
        if (status) {
            gtm.track(gtm.events.SURVEY_ACTIVATED, gtm.categories.SURVEY_ACTIVATED, { id: state.id });
        } else {
            gtm.track(gtm.events.SURVEY_PAUSED, gtm.categories.SURVEY_PAUSED, { id: state.id });
        }
    },
    async updateUniqueName({ commit, state }, value) {
        commit('updateUniqueName', value );
        await api.survey.saveUniqueName(state.id, state.uniqueName);
        gtm.track(gtm.events.SURVEY_UNIQUE_LINK_UPDATED, gtm.categories.SURVEY_UNIQUE_LINK_UPDATED, { id: state.id, uniqueName: value });
    },
    savePublishSettings: debounce(async ({ state, commit }, publishSettings) => {
        commit('setMetaData', {
            ...state,
            is_multiple_on : publishSettings.isMultipleFillingOn,
            multiple_restriction: publishSettings.multiFillRestriction,
            is_anonymous_on: publishSettings.isIpCollectionOn !== undefined ? !publishSettings.isIpCollectionOn : undefined,
            is_autosave_on: publishSettings.isAutosaveOn,
            password: publishSettings.password,
            target_number : publishSettings.responseLimit,
            multifilling_restriction_type: publishSettings.multiFillingRestrictionType,
            single_response_limit_target: publishSettings.singleResponseLimitTarget,
            overall_response_limit_target: publishSettings.overallResponseLimitTarget,
            resumable_survey_filling_guid:  publishSettings.resumableSurveyFillingGuid,
        });
        await api.survey.updatePublishSettings({
            id: state.id,
            isMultipleFillingOn: state.isMultipleFillingOn,
            multiFillRestriction: state.multiFillRestriction,
            isAnonymousOn: !state.isIpCollectionOn,
            isAutosaveOn: state.isAutosaveOn,
            responseLimit: state.responseLimit,
            password: state.password,
            multiFillingRestrictionType: state.multiFillingRestrictionType,
            single_response_limit_target: state.singleResponseLimitTarget,
            overall_response_limit_target: state.overallResponseLimitTarget,
            resumable_survey_filling_guid:  state.resumableSurveyFillingGuid,
        });
    }),
    async deleteParameter({ dispatch, commit }, guid) {
        commit('deleteParameter', guid);
        await dispatch('saveUrlParameters');
    },
    async updateParameters({ dispatch, commit }, parameters) {
        commit('updateParameters', parameters);
        await dispatch('saveUrlParameters');
    },
    async saveUrlParameters({ state }) {
        const parameters = state.urlParameters.filter(param => param.name !== '');

        parameters.forEach((param, index) => {
            const originalName = param.name;
            let suffix = 1;
            while (parameters.slice(0,index).some(p => p.name === param.name)) {
                suffix++;
                param.name = originalName + suffix;
            }
        });

        await api.survey.saveUrlParameters({ id: state.id, parameters: parameters });
    },
    async saveBgImage({ state, commit }, file) {
        const bgImage = await api.survey.saveBgImage(state.id, file);
        commit('updateBgImage', bgImage);
    },
    async deleteBg({ state, commit }) {
        await api.survey.deleteBgImage(state.id);
        commit('updateBgImage', null);
    },
    async saveFavicon({ state, commit }, file) {
        const faviconInfo = await api.survey.saveFavicon(state.id, file);
        commit('updateFavicon', faviconInfo['favicon']);
        commit('updateFaviconUrl', faviconInfo['favicon_url']);
    },
    async deleteFavicon({ state, commit }) {
        await api.survey.deleteFavicon(state.id);
        commit('updateFavicon', null);
        commit('updateFaviconUrl', null);
    },
    async setIsMultilang({ state, commit }, value) {
        await setIsMultiLang(state.id, value);
        commit('setIsMultilang', value);
    },
    async saveTranslations({ state, getters }, { draftTranslations, liveTranslations, activeLanguages, publishedAt }) {
        const translations = { ...state.translations };

        if (draftTranslations !== undefined) {
            translations.draftTranslations = state.hasNewDropdownOptionsStoring ?
                nullifyDropdownOptions(draftTranslations, getters.dropdownElementsWithOptionsId) :
                draftTranslations;
        }

        if (liveTranslations !== undefined) {
            translations.liveTranslations = state.hasNewDropdownOptionsStoring ?
                nullifyDropdownOptions(liveTranslations, getters.dropdownElementsWithOptionsId) :
                liveTranslations;
        }

        if (activeLanguages !== undefined) {
            translations.activeLanguages = activeLanguages;
        }

        if (publishedAt !== undefined) {
            translations.publishedAt = publishedAt;
        }

        await saveTranslations(state.id, translations);
    },
    async syncTranslations({ state, dispatch, getters }, { title, description, thank_you_title, thank_you_description, questions = null, disqualificationPages = null }) {
        if (!getters.hasTranslations) {
            return;
        }

        const translations = cloneDeep(state.translations);
        const languages = Object.keys(translations.draftTranslations);

        // If default value is null or undefined, but if the translation is not, it should be nullified
        for (const l of languages) {

            // Value check is not enough because due to missing ones, new props with empty string keys would have been added at this point
            const meta = { title, description, thank_you_title, thank_you_description };
            for (const prop in meta) {
                const value = meta[prop];
                if (value !== undefined && (value === null || removeHtmlFromString(value) === '') && translations.draftTranslations[l][prop] !== null) {
                    translations.draftTranslations[l][prop] = null;
                    if (translations.liveTranslations[l] && translations.liveTranslations[l][prop] !== null) {
                        translations.liveTranslations[l][prop] = null;
                    }
                }
            }

            // Question prop is empty when called from metadata or disqualifications page save, so by setting it default [] and not checking it would delete all existing question translations
            if (Array.isArray(questions)) {
                questions.forEach((q) => {
                    const dq = translations.draftTranslations[l].questions.find(qu => qu.guid === q.guid);
                    const lq = translations.liveTranslations[l] ? translations.liveTranslations[l].questions.find(qu => qu.guid === q.guid) : null;

                    for (const prop of ['title', 'help', 'other_option_label', 'dkna_option_label', 'range_start_label', 'range_end_label', 'suffix']) {
                        if (q[prop] !== undefined && (q[prop] === null || removeHtmlFromString(q[prop]) === '')) {
                            if (dq && dq[prop] !== null) {
                                dq[prop] = null;
                                if (lq && lq[prop] !== null) {
                                    lq[prop] = null;
                                }
                            }
                        }
                    }
                    if (q.rating_labels && Array.isArray(q.rating_labels)) {
                        // Rating_labels is [] by default if exists, if switched on it's always 5 elements array, empty values are empty strings
                        q.rating_labels.forEach((label, index) => {
                            if (label === '') {
                                if (dq && dq.rating_labels[index] !== '') {
                                    dq.rating_labels[index] = '';
                                    if (lq && lq.rating_labels[index] !== '') {
                                        lq.rating_labels[index] = '';
                                    }
                                }
                            }
                        });
                    }
                    if (q.condition_groups && Array.isArray(q.condition_groups)) {
                        q.condition_groups.forEach((conditionGroup) => {
                            // Only change title type conditions can have translations
                            if (conditionGroup.action === actionEnum.CHANGE && removeHtmlFromString(conditionGroup.action_payload) === '') {
                                if (dq && dq.condition_groups) {
                                    dq.condition_groups = dq.condition_groups.map(cg => {
                                        if (cg.order === conditionGroup.order) {
                                            cg.action_payload = '';
                                        }
                                        return cg;
                                    });
                                    if (lq && lq.condition_groups) {
                                        lq.condition_groups = lq.condition_groups.map(cg => {
                                            if (cg.order === conditionGroup.order) {
                                                cg.action_payload = '';
                                            }
                                            return cg;
                                        });
                                    }
                                }
                            }
                        });
                    }

                    if (q.type !== elementTypes.META && !dq) {
                        translations.draftTranslations[l].questions = [
                            ...translations.draftTranslations[l].questions,
                            {
                                guid: q.guid,
                                options: q.options?.map(() => ''),
                                rating_labels: q.rating_labels?.map(() => ''),
                                ranges: !['none', 'icon'].includes(q.label_type) ? q.ranges?.map(({ weight }) => ({ weight })) : [],
                                conditions: q.condition_groups?.filter(cg => cg.action === actionEnum.CHANGE).map(({ order }) => ({ order })),
                            }
                        ];
                    }
                });

                // Only keep existing questions (delete deleted ones)
                const questionGuids = questions.map(qu => qu.guid) || [];
                translations.draftTranslations[l].questions = translations.draftTranslations[l].questions.filter(qu => qu && qu.guid && questionGuids.includes(qu.guid));
                if (translations.liveTranslations[l]) {
                    translations.liveTranslations[l].questions = translations.liveTranslations[l].questions.filter(qu => qu && qu.guid && questionGuids.includes(qu.guid));
                }
            }

            if (Array.isArray(disqualificationPages)) {
                disqualificationPages.forEach((dp) => {
                    const ddp = translations.draftTranslations[l].disqualification_pages.find(disqPage => disqPage.id === dp.id);
                    const ldp = translations.liveTranslations[l] ? translations.liveTranslations[l].disqualification_pages.find(disqPage => disqPage.id === dp.id) : null;

                    for (const prop of ['title', 'description']) {
                        if (dp[prop] !== undefined && (dp[prop] === null || removeHtmlFromString(dp[prop]) === '')) {
                            if (ddp && ddp[prop] !== null) {
                                ddp[prop] = null;
                                if (ldp && ldp[prop] !== null) {
                                    ldp[prop] = null;
                                }
                            }
                        }
                    }

                    if (!ddp) {
                        translations.draftTranslations[l].disqualification_pages = [
                            ...translations.draftTranslations[l].disqualification_pages,
                            { guid: dp.id }
                        ];
                    }
                });

                // Only keep existing disq pages (delete deleted ones)
                const disqPageIds = disqualificationPages.map(dp => dp.id) || [];
                translations.draftTranslations[l].disqualification_pages = translations.draftTranslations[l].disqualification_pages.filter(dp => disqPageIds.includes(dp.id));
                if (translations.liveTranslations[l]) {
                    translations.liveTranslations[l].disqualification_pages =  translations.liveTranslations[l].disqualification_pages.filter(dp => disqPageIds.includes(dp.id));
                }
            }
        }

        // Only save if there is change in translations
        if (!isEqual(translations, state.translations)) {
            state.translations = translations;
            await dispatch('saveTranslations', { ...translations });
        }
    },
    // Active languages might become not fully translated due to new questions/options
    // If user chooses to deactivate instead of fallback, deactivate active languages
    async deactivateTranslations({ dispatch }) {
        await dispatch('saveActiveLanguages', { activeLanguages: [] });
    },
    async getSurveyQuestionOptions({ state, commit }, { dimensionId, type = 'original', langId }) {
        const options = await getSurveyQuestionOptions({ datasetId: state.datasetId, dimensionId, type, langId });

        commit('setSurveyQuestionOptions', { guid: dimensionId, options });
    },
    async saveOptionsFromFile({ state }, { dimensionId, type = 'original', langId, options }) {
        return await saveOptionsFromFile({ datasetId: state.datasetId, dimensionId, type, langId, options });
    },
    async deleteOptionsFromFile({ state, commit }, { dimensionId, type = 'original', langId }) {
        if (state.hasNewDropdownOptionsStoring) {
            await deleteOptionsFromFile({ datasetId: state.datasetId, dimensionId, type, langId });
        }
        else {
            commit('setOptions', {
                guid:   dimensionId,
                options: [],
            });
        }
    },
    async handleDropdownFromFileCopy({ state, dispatch }, { guid }) {
        const elementToCopy = state.elements.find(element => element.guid === guid);
        if (!elementToCopy || elementToCopy.type !== elementTypes.DROPDOWN || !elementToCopy.options_from_file) {
            return;
        }

        // We have to save elements before save copied dropdown options
        await dispatch('saveElementsNow');
        if (elementToCopy.options_from_file) {
            let newOptionsId = null;
            if (elementToCopy.options_id && elementToCopy.options.length === 0) {
                const options = await getSurveyQuestionOptions({ datasetId: state.datasetId, dimensionId: elementToCopy.guid, type: 'original' });
                newOptionsId = await saveOptionsFromFile({ datasetId: state.datasetId, dimensionId: state.activeElement, options, type: 'original' });
            } else if (elementToCopy.options.length > 0) {
                newOptionsId = await saveOptionsFromFile({ datasetId: state.datasetId, dimensionId: state.activeElement, options: elementToCopy.options, type: 'original' });
            }
            if (newOptionsId) {
                state.elements = state.elements
                    .map(element => element.guid === state.activeElement ?
                        {
                            ...element,
                            options_id: newOptionsId,
                            options: elementToCopy.options?.length > 0 ? elementToCopy.options : null,
                            options_from_file: elementToCopy.options_from_file
                        } : element

                    );
            }
        }
    },
    async getOptionsFromFileCount({ state }, { dimensionId, type = 'original', langId }) {
        return await getOptionsFromFileCount({ datasetId: state.datasetId, dimensionId, type, langId });
    },
    async getDropdownOptionsTranslations({ state, commit }, { dimensionId, type = 'draft_translation', langId }) {
        const options = await getSurveyQuestionOptions({ datasetId: state.datasetId, dimensionId, type, langId });

        const nickname = getNicknameFromId(langId);

        const draftTranslations = produce(state.translations.draftTranslations, (draft) => {
            draft[nickname].questions = draft[nickname].questions.map(q => {
                if (q.guid != dimensionId) return q;

                return {
                    ...q,
                    options
                };
            });
        });

        commit('setTranslations', { draftTranslations });
    },
    async loadDropdownOptionsAndTranslations({ state, getters, dispatch }) {
        const promises = [];

        getters.builderElements
            .filter(el => el.type == elementTypes.DROPDOWN)
            .forEach(el => {
                if (el.options_id && (el.options === null || el.options.length === 0)) {
                    promises.push(dispatch('getSurveyQuestionOptions', { dimensionId: el.guid }));
                }

                Object.keys(state.translations.draftTranslations)
                    .forEach(nickname => {
                        if (state.translations.draftTranslations[nickname].questions.find(q => q.guid === el.guid)?.options === null) {
                            promises.push(dispatch('getDropdownOptionsTranslations', {
                                dimensionId: el.guid,
                                langId: getIdFromNickname(nickname),
                            }));
                        }
                    });
            });

        await Promise.all(promises);
    },
    async saveTitleTranslation({ state, commit, dispatch, getters }, { language, title }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);

        const draftTranslationsWithTitle = produce(draftTranslations, (draft) => {
            draft[language].title = title;
        });

        await dispatch('saveTranslations', {
            draftTranslations: draftTranslationsWithTitle,
            liveTranslations
        });

        commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => { draft[language].title = title; }) });
    },
    async saveDescriptionTranslation({ state, commit, dispatch , getters }, { language, description }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);

        const draftTranslationsWithDescription = produce(draftTranslations, (draft) => {
            draft[language].description = description;
        });

        await dispatch('saveTranslations', {
            draftTranslations: draftTranslationsWithDescription,
            liveTranslations
        });

        commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => { draft[language].description = description; }) });
    },
    async saveThankYouPageTranslation({ state, commit, dispatch, getters }, { language, thankYouPage }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);

        const draftTranslationsWithThankYouPage = produce(draftTranslations, (draft) => {
            draft[language].thank_you_title = thankYouPage.thank_you_title;
            draft[language].thank_you_description = thankYouPage.thank_you_description;
        });

        await dispatch('saveTranslations', {
            draftTranslations: draftTranslationsWithThankYouPage,
            liveTranslations
        });

        commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => {
            draft[language].thank_you_title = thankYouPage.thank_you_title;
            draft[language].thank_you_description = thankYouPage.thank_you_description;
        }) });
    },
    async saveQuestionTranslation({ state, getters, commit, dispatch }, { language, questionDraft }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);

        if (
            state.hasNewDropdownOptionsStoring
            && getters.builderElements.find(el => el.guid === questionDraft.guid).options_from_file
        ) {
            const optionsId = await saveOptionsFromFile({
                datasetId: state.datasetId,
                dimensionId: questionDraft.guid,
                langId: getIdFromNickname(language),
                options: questionDraft.options,
                type: 'draft_translation'
            });

            const draftTranslationsWithoutOptions = produce(draftTranslations, (draft) => {
                const index = draft[language].questions.findIndex(question => question.guid === questionDraft.guid);

                draft[language].questions[index] = {
                    ...questionDraft,
                    options: null,
                    options_id: optionsId
                };
            });

            await dispatch('saveTranslations', {
                draftTranslations: draftTranslationsWithoutOptions,
                liveTranslations
            });

            commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => {
                const index = draft[language].questions.findIndex(question => question.guid === questionDraft.guid);
                draft[language].questions[index] = {
                    ...questionDraft,
                    options_id: optionsId
                };
            }) });
        }
        else {
            const draftTranslationsWithOptions = produce(draftTranslations, (draft) => {
                const index = draft[language].questions.findIndex(question => question.guid === questionDraft.guid);

                // This null filter solves the issue of PWC. Somehow there are a lot of null options for one of the draft multiple choice questions,
                // but I can't reproduce this even with question option deletion
                // Some question types e.g. explanation don't have the options prop at all
                draft[language].questions[index] = { ...questionDraft, options: questionDraft.options ? questionDraft.options.filter(o => o !== null) : null };
            });

            await dispatch('saveTranslations', {
                draftTranslations: draftTranslationsWithOptions,
                liveTranslations,
            });
            commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => {
                const index = draft[language].questions.findIndex(question => question.guid === questionDraft.guid);

                // This null filter solves the issue of PWC. Somehow there are a lot of null options in one of the draft multiple choice questions,
                // but I can't reproduce this even by deleting question options
                // Some question types e.g. explanation don't have the options prop at all
                draft[language].questions[index] = { ...questionDraft, options: questionDraft.options ? questionDraft.options.filter(o => o !== null) : null };
            }) });
        }
    },
    async saveDisqualificationPageTranslation({ state, commit, dispatch, getters }, { language, disqualificationPage }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);

        const draftTranslationsWithDisqualificationPage = produce(draftTranslations, (draft) => {
            const index = draft[language].disqualification_pages.findIndex(page => page.id === disqualificationPage.id);

            draft[language].disqualification_pages[index] = disqualificationPage;
        });

        await dispatch('saveTranslations', {
            draftTranslations: draftTranslationsWithDisqualificationPage,
            liveTranslations,
        });

        commit('setTranslations', { draftTranslations: produce(state.translations.draftTranslations, (draft) => {
            const index = draft[language].disqualification_pages.findIndex(page => page.id === disqualificationPage.id);

            draft[language].disqualification_pages[index] = disqualificationPage;
        }) });
    },
    async publishTranslations({ state, commit, dispatch, getters }) {
        const draftTranslations = nullifyDropdownOptions(state.translations.draftTranslations, getters.dropdownElementsWithOptionsId);
        let liveTranslations = cloneDeep(state.translations.draftTranslations); // needs to be cloned this way so properties like options and options_id can be writeable
        const publishedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

        const shouldSaveFirst = Object.keys(state.translations.liveTranslations).length < Object.keys(state.translations.draftTranslations).length;
        if (shouldSaveFirst) {
            // save translations must run here first otherwise live translations don't exist yet one backend to save options id on
            await dispatch('saveTranslations', {
                draftTranslations,
                liveTranslations: nullifyDropdownOptions(liveTranslations, getters.dropdownElementsWithOptionsId, 'both'),
                publishedAt
            });
        }

        // needs to overwrite options_id with current ones instead of draft translations' option_ids before setting translations
        if (state.hasNewDropdownOptionsStoring) {
            Object.keys(liveTranslations).forEach(nickname => {
                liveTranslations[nickname].questions
                    .filter(question => getters.dropdownElementsWithOptionsId.includes(question.guid))
                    .forEach(async (question) => {
                        question.options_id = await saveOptionsFromFile({
                            datasetId: state.datasetId,
                            dimensionId: question.guid,
                            langId: getIdFromNickname(nickname),
                            options: question.options,
                            type: 'live_translation'
                        });
                    });
            });
        }

        if (!shouldSaveFirst) {
            await dispatch('saveTranslations', {
                draftTranslations,
                liveTranslations: nullifyDropdownOptions(liveTranslations, getters.dropdownElementsWithOptionsId),
                publishedAt
            });
        }

        commit('setTranslations', {
            liveTranslations,
            publishedAt,
        });
    },
    async saveActiveLanguages({ state, commit, dispatch }, { activeLanguages }) {
        const draftTranslations = JSON.parse(JSON.stringify(state.translations.draftTranslations));
        const liveTranslations = JSON.parse(JSON.stringify(state.translations.liveTranslations));

        await dispatch('saveTranslations', {
            draftTranslations,
            liveTranslations,
            activeLanguages,
        });

        commit('setTranslations', {
            activeLanguages
        });
    },
    async revertTranslations({ state, commit, dispatch, getters }) {
        const liveTranslations = nullifyDropdownOptions(state.translations.liveTranslations, getters.dropdownElementsWithOptionsId);
        let draftTranslations = cloneDeep(state.translations.liveTranslations);

        if (state.hasNewDropdownOptionsStoring) {
            Object.keys(draftTranslations).forEach(nickname => {
                draftTranslations[nickname].questions
                    .filter(question => getters.dropdownElementsWithOptionsId.includes(question.guid))
                    .forEach(async (question) => {
                        // during revert options ids cannot change
                        await saveOptionsFromFile({
                            datasetId: state.datasetId,
                            dimensionId: question.guid,
                            langId: getIdFromNickname(nickname),
                            options: question.options,
                            type: 'draft_translation'
                        });
                    });
            });
        }

        await dispatch('saveTranslations', {
            draftTranslations: nullifyDropdownOptions(draftTranslations, getters.dropdownElementsWithOptionsId),
            liveTranslations,
        });

        commit('setTranslations', {
            draftTranslations: draftTranslations,
        });
    },
    async downloadDropdownOptions({ state, getters }, { guid, language }) {
        const element = getters.builderElements.find(question => question.guid === guid);

        if (!element) throw new Error('question not found');

        let options;

        if (state.hasNewDropdownOptionsStoring) {
            options = await getSurveyQuestionOptions({
                datasetId: state.datasetId,
                dimensionId: guid,
                type: !language ? 'original' : 'draft_translation',
                langId: language && getIdFromNickname(language),
            });
        }
        else if (language) {
            // translation options
            const question = state.translations.draftTranslations[language].questions.find(question => question.guid === guid);

            if (!question) throw new Error('question translation not found');

            options = question.options;
        }
        else {
            // benchmark options
            options = element.options;
        }

        const fileName = striptags(element.title) + '.xlsx';

        const workbook = XLSX.utils.book_new();

        const workSheet = XLSX.utils.aoa_to_sheet(options.map(option => [typeof option === 'string' ? option : option.value]));

        XLSX.utils.book_append_sheet(workbook, workSheet, 'options');

        XLSX.writeFile(workbook, fileName);
    },
};
