var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltipText),expression:"getTooltipText"}],staticClass:"inline-flex items-center text-small rounded px-1 py-1 ml-1 mb-2 cursor-pointer",class:_vm.opinionClasses},[_c('div',{on:{"click":function($event){_vm.noEditPopup ? _vm.drillDown(_vm.entity) : _vm.$emit('edit')}}},[(_vm.entity.subtype === 'manual_custom_label')?_c('icon',{staticClass:"mx-1",attrs:{"icon":"user-tag","type":_vm.notSolidIcon ? 'fal' : 'fas'}}):(_vm.entity.type === 'custom_label')?_c('icon',{staticClass:"mx-1",attrs:{"icon":"tag","type":"fas"}}):_vm._e(),(_vm.entity.category)?_c('span',[_vm._v(_vm._s(_vm.entity.category)+" / ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.entity.name))]),(_vm.labelTranslationEnabled && _vm.entity.engName)?_c('span',{staticClass:"pl-1"},[_vm._v(" ("+_vm._s(_vm.entity.engName)+") ")]):_vm._e()],1),_c('div',{staticClass:"ml-1 flex"},[(_vm.canEdit && _vm.canEditLabels)?_c('div',{staticClass:"rounded-l w-4 h-4 p-1 items-center justify-center text-bg",class:{
                'hidden group-hover:flex': !_vm.alwaysShowButtons,
                'flex': _vm.alwaysShowButtons,
                'bg-red-600': _vm.entity.opinionIndex < 0,
                'bg-green-600': _vm.entity.opinionIndex > 0,
                'bg-neutral-700': _vm.entity.opinionIndex === 0
            },attrs:{"data-test-id":"increase-score"},on:{"click":function($event){_vm.$emit('change-oi', { id: _vm.entity.id, subtype: _vm.entity.subtype, value: Math.floor(Number(_vm.entity.opinionIndex)) - 1 })}}},[_c('icon',{attrs:{"icon":"minus"}})],1):_vm._e(),_c('div',{staticClass:"flex items-center justify-center w-4 h-4 p-1 text-bg",class:{
                'rounded group-hover:rounded-none': _vm.canEdit && _vm.canEditLabels && !_vm.alwaysShowButtons,
                'bg-red-500': _vm.entity.opinionIndex < 0,
                'bg-green-500': _vm.entity.opinionIndex > 0,
                'bg-neutral-600': _vm.entity.opinionIndex === 0
            }},[_vm._v(" "+_vm._s(_vm.entity.opinionIndex)+" ")]),(_vm.canEdit && _vm.canEditLabels)?_c('div',{staticClass:"rounded-r w-4 h-4 pb-px items-center justify-center text-bg",class:{
                'hidden group-hover:flex': !_vm.alwaysShowButtons,
                'flex': _vm.alwaysShowButtons,
                'bg-red-600': _vm.entity.opinionIndex < 0,
                'bg-green-600': _vm.entity.opinionIndex > 0,
                'bg-neutral-700': _vm.entity.opinionIndex === 0
            },attrs:{"data-test-id":"increase-score"},on:{"click":function($event){_vm.$emit('change-oi',{ id: _vm.entity.id, subtype: _vm.entity.subtype, value: Math.floor(Number(_vm.entity.opinionIndex)) + 1 })}}},[_c('icon',{attrs:{"icon":"plus"}})],1):_vm._e(),(_vm.canEdit && _vm.canEditLabels)?_c('div',{staticClass:"items-center justify-center w-4 h-4 p-1 ml-1 rounded text-bg",class:{
                'hidden group-hover:flex': !_vm.alwaysShowButtons,
                'flex': _vm.alwaysShowButtons,
                'bg-red-600': _vm.entity.opinionIndex < 0,
                'bg-green-600': _vm.entity.opinionIndex > 0,
                'bg-neutral-700': _vm.entity.opinionIndex === 0
            },on:{"click":function($event){return _vm.$emit('remove-label', { id: _vm.entity.id, subtype: _vm.entity.subtype })}}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }